<template>
    <div class="recruitBox">
        <!-- 人才招聘 -->
        <div class="perSonnelBox">
            <!-- 大背景 -->
            <div class="psBigBack">
                <a :href="getAdvData(35).children[0].url" target="_blank" rel="noopener noreferrer">
                    <img v-if="getAdvData(35).children" :src="getAdvData(35).children[0].image" class="pbImg" />
                </a>
                <!-- 标题 -->
            </div>
            <div class="psbTitle">人才招聘</div>
            <!-- 招聘岗位盒子 -->
            <div class="psbContent">
                <!-- 背景 -->
                <div class="pcBack"></div>
                <!-- 6个招聘盒子 -->
                <div class="pcList">
                    <ul class="pclUl">
                        <template v-for="item in theRLs">
                            <li class="pclLi" v-if="item.status" :key="item.id">
                                <!-- 图标 -->
                                <div class="pclIcon">
                                    <img class="plIcons" :src="item.head_image" />
                                </div>
                                <!-- 部门 -->
                                <div class="pclTop">{{ item.title }}</div>
                                <!-- 岗位列表 -->
                                <div class="pclList">
                                    <ul class="pclUl2">
                                        <template v-for="itemList in item.children">
                                            <li
                                                class="pcl2Li"
                                                v-if="itemList.status"
                                                :key="itemList.id"
                                                @click="goPsbData(itemList.id)"
                                            >
                                                {{ itemList.title }}
                                            </li>
                                        </template>
                                    </ul>
                                </div>
                            </li>
                        </template>
                        <li class="pclLis" @click="$router.push('/invitPage')">
                            <div class="plText">邀请好友 <br />来应聘</div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <!-- 人才战略 -->
        <div class="straTegyBox wow animate__animated animate__slideInUp">
            <!-- 标题 -->
            <div class="stbTitle">
                <div class="stBig">人才战略</div>
                <div class="stSamll">TALENT STRATEGY</div>
            </div>
            <!-- 简介 -->
            <div class="stbText">
                {{ rczl.intro }}
            </div>
            <!-- 大图片 -->
            <div class="stbBigImg" id="viewer">
                <!-- <div v-for="el in list" :key="el">
                    <img :src="e.src" v-for="(e, i) in el" :key="i" @click="viewer.view(e.index)" />
                </div> -->
                <div v-for="(el, i) in rczl.image" :key="el.id">
                    <img :src="el" @click="viewer.view(i)" />
                </div>
            </div>
        </div>
        <!-- 福利待遇 -->
        <div class="fringeBox wow animate__animated animate__slideInUp">
            <!-- 标题 -->
            <div class="fgbTitle">
                <div class="ftBig">福利待遇</div>
                <div class="ftSmall">FRINGE BENEFITS</div>
            </div>
            <div class="content">
                <div class="wow animate__animated animate__fadeInUp">
                    <div class="center">
                        <div>{{ fldy.title }}</div>
                    </div>
                </div>
                <template v-for="el in fldy_second">
                    <div :key="el.id" v-if="el.id" class="yuan wow animate__animated animate__fadeInUp">{{ el.title }}</div>
                </template>
                <!-- <div class="yuan wow animate__animated animate__fadeInUp">发展空间</div> -->
                <ul>
                    <template v-for="(el, i) in arr">
                        <li
                            v-show="el.id"
                            :key="el.id"
                            class="wow animate__animated animate__fadeInUp"
                            :data-wow-delay="`${(i + 1) * 0.1}s`"
                        >
                            <template v-if="el.position == 'top'">
                                <div class="name">{{ el.name }}</div>
                            </template>

                            <div class="items">
                                <p v-for="item in el.data" :key="item">{{ item }}</p>
                            </div>

                            <template v-if="el.position == 'bottom'">
                                <div class="name">{{ el.name }}</div>
                            </template>
                            <div class="xian">
                                <!-- 线 -->
                                <img src="https://iv.vu818.com/img/vu818fldy.png" alt="" />
                            </div>
                        </li>
                    </template>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import Viewer from "viewerjs";
import "viewerjs/dist/viewer.css";
import Swiper from "swiper";
import "swiper/css/swiper.min.css";

export default {
    name: "Recruit",
    inject: ["getAdvData"],
    data() {
        return {
            // 人才招聘
            theRLs: [],
            // 人才战略
            rczl: {},
            // 福利待遇
            fldy: {},
            fldy_second: {},
            arr: [
                {
                    name: "工资结构1",
                    data: ["工资"],
                    position: "top",
                },
                {
                    name: "工资结构2",
                    data: ["工资"],
                    position: "bottom",
                },
                {
                    name: "工资结构3",
                    data: ["工资"],
                    position: "top",
                },
                {
                    name: "工资结构4",
                    data: ["工资"],
                    position: "bottom",
                },
                {
                    name: "工资结构5",
                    data: ["工资"],
                    position: "bottom",
                },
                {
                    name: "工资结构6",
                    data: ["工资"],
                    position: "bottom",
                },
            ],
        };
    },
    mounted() {
        this.$AOS.init({
            offset: 200,
            duration: 600,
            easing: "ease-in-sine",
            delay: 100,
        });
        new this.$wow.WOW().init({
            boxClass: "wow",
            animateClass: "animate__animated",
            offset: 0,
            mobile: true,
            live: true,
        });
    },
    created() {
        axios.get("/api/recruit/recruitshow").then(({ data }) => {
            this.theRLs = data;
        });
        axios.get("/api/recruit/strategyshow").then(({ data }) => {
            this.rczl = data;
            this.$nextTick(function () {
                this.viewer = new Viewer(document.getElementById("viewer"), {
                    title: false,
                });
                // this.swiper = new Swiper("#RecruitSw", {
                //     freeMode: true,
                // });
            });
        });
        axios.get("/api/recruit/welfareshow").then(({ data }) => {
            data = data[0];
            this.fldy = data;
            if (data.children.length > 3) {
                this.fldy_second = data.children.slice(0, 3);
            } else {
                this.fldy_second = [...data.children];
                for (let i = 0; i < 3 - data.children.length; i++) {
                    this.fldy_second.push({ children: [] });
                }
            }
            let a = [];
            this.fldy_second.forEach(e => {
                let o = e.children[0],
                    t = e.children[1];
                a.push(o ? o : { children: [] }, t ? t : { children: [] });
            });
            this.arr = this.arr.map((e, i) => {
                return {
                    name: a[i].title,
                    data: a[i].children.map(e => e.title),
                    id: a[i].id,
                    position: e.position,
                };
            });
        });
    },
    methods: {
        // 跳转策划部详情页
        goPsbData(id) {
            this.$router.push(`/region?id=${id}`);
        },
    },
    computed: {
        list() {
            if (this.rczl.image) {
                const yuan = this.rczl.image.map((e, i) => {
                    return {
                        src: e,
                        index: i,
                    };
                });
                // yuan.forEach((e, i) => (e.i = i));
                let length = yuan.length;
                let num = 0;
                let arr = [];
                function count(n) {
                    if (length < 6) {
                        return length;
                    } else {
                        length -= 6;
                        num++;
                        return count(n);
                    }
                }
                count(length);
                for (let i = 0; i < num; i++) {
                    arr.push(yuan.slice(i * 6, i * 6 + 6));
                }
                if (length > 0) {
                    arr.push(yuan.slice(length * -1));
                }
                console.log(arr);
                return arr;
            }
            return [];
        },
    },
};
</script>

<style lang="less" scoped>
.recruitBox {
    width: 100%;
    // 人才招聘
    .perSonnelBox {
        width: 100%;
        position: relative;
        z-index: 2;
        .psBigBack {
            width: 100%;
            margin: 0 auto;
            height: 65vh;
            position: relative;
            .pbImg {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        // 标题
        .psbTitle {
            width: 30%;
            height: 100px;
            line-height: 100px;
            font-size: 50px;
            text-align: center;
            margin: 0 auto;
        }
        // 招聘岗位盒子
        .psbContent {
            width: 100%;
            // height: 400px;
            margin-bottom: 40px;
            position: relative;
            // 招聘盒子
            .pcList {
                width: 90%;
                height: 300px;
                margin: 0 auto;
                margin-top: 140px;
                .pclUl {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    // justify-content: space-around;
                    .pclLi {
                        width: 15%;
                        height: 100%;
                        background-color: white;
                        border-radius: 20px;
                        box-shadow: 1px 1px 5px 2px gainsboro;
                        transition: 0.5s;
                        margin-right: calc(10% / 5);
                        // 图标
                        .pclIcon {
                            width: 90px;
                            height: 30%;
                            margin: 0 auto;
                            text-align: center;
                            // line-height: 90px;
                            padding-top: 10px;
                            position: relative;
                            overflow: hidden;
                            .plIcons {
                                // position: absolute;
                                // top: -748px;
                                // right: -125px;
                                width: 70px;
                            }
                            .plIcons2 {
                                position: absolute;
                                top: -750px;
                                right: -217px;
                            }
                            .plIcons3 {
                                width: 1850px;
                                position: absolute;
                                top: -630px;
                                left: 15px;
                            }
                            .plIcons4 {
                                width: 1850px;
                                position: absolute;
                                top: -805px;
                                left: -514px;
                            }
                            .plIcons5 {
                                width: 1800px;
                                position: absolute;
                                top: -950px;
                                left: -175px;
                            }
                            .iconfont {
                                font-size: 60px;
                            }
                        }
                        //  部门
                        .pclTop {
                            width: 100%;
                            height: 10%;
                            font-size: 20px;
                            font-weight: 900;
                            text-align: center;
                            color: #7781f1;
                        }
                        // 岗位列表
                        .pclList {
                            width: 100%;
                            height: 60%;
                            .pclUl2 {
                                width: 100%;
                                height: 100%;
                                min-height: 21px;
                                display: flex;
                                flex-direction: column;
                                justify-content: space-around;
                                .pcl2Li {
                                    width: 80%;
                                    height: 21px;
                                    line-height: 21px;
                                    margin: 0 auto;
                                    margin-top: 10px;
                                    text-align: center;
                                    cursor: pointer;
                                }
                                .pcl2Li:hover {
                                    color: #7781f1;
                                }
                            }
                        }
                    }
                    .pclLis {
                        width: 15%;
                        height: 100%;
                        background-color: white;
                        border-radius: 20px;
                        box-shadow: 1px 1px 5px 2px gainsboro;
                        transition: 0.5s;
                        cursor: pointer;
                        .plText {
                            width: 70%;
                            height: 50px;
                            line-height: 25px;
                            text-align: center;
                            font-size: 20px;
                            margin: 0 auto;
                            margin-top: 120px;
                            font-weight: 900;
                        }
                        .plText:hover {
                            color: #7781f1;
                        }
                    }
                    .pclLi:hover {
                        box-shadow: 2px 2px 10px 5px gainsboro;
                        transform: scale(1.05);
                    }
                }
            }
        }
    }
    // 人才战略
    .straTegyBox {
        position: relative;
        z-index: 1;
        width: 100%;

        // 标题
        .stbTitle {
            width: 40%;
            height: 100px;
            margin: 0 auto;
            .stBig {
                width: 100%;
                height: 70px;
                line-height: 70px;
                font-size: 50px;
                text-align: center;
            }
            .stSamll {
                width: 100%;
                height: 30px;
                line-height: 30px;
                font-size: 22px;
                text-align: center;
            }
        }
        // 简介
        .stbText {
            width: 80%;
            height: 80px;
            line-height: 25px;
            text-align: left;
            margin: 0 auto;
            margin-top: 50px;
        }
        // 大图片
        .stbBigImg {
            width: 80%;
            width: 1280px;
            min-height: 700px;
            margin: 0px auto;
            padding: 20px;
            // border: 1px solid black;
            position: relative;
            & > div {
                position: absolute;
                width: 100px;
                height: 100px;
                transition: 0.5s;
            }
            & > div:nth-of-type(1) {
                left: 450px;
                width: 300px;
                height: 150px;
            }
            & > div:nth-of-type(2) {
                left: 770px;
                top: 40px;
                width: 130px;
                height: 130px;
            }
            & > div:nth-of-type(3) {
                left: 450px;
                top: 190px;
                width: 240px;
                height: 180px;
            }
            & > div:nth-of-type(4) {
                left: 710px;
                top: 190px;
                width: 260px;
                height: 290px;
            }
            & > div:nth-of-type(5) {
                left: 170px;
                top: 80px;
                width: 260px;
                height: 290px;
            }
            & > div:nth-of-type(6) {
                left: 990px;
                top: 80px;
                width: 200px;
                height: 270px;
            }
            & > div:nth-of-type(7) {
                left: 30px;
                top: 250px;
                width: 120px;
                height: 120px;
            }
            & > div:nth-of-type(8) {
                left: 490px;
                top: 390px;
                width: 200px;
                height: 300px;
            }
            & > div:nth-of-type(9) {
                left: 200px;
                top: 390px;
                width: 270px;
                height: 280px;
            }
            & > div:nth-of-type(10) {
                left: 710px;
                top: 500px;
                width: 250px;
                height: 180px;
            }
            & > div:nth-of-type(11) {
                left: 990px;
                top: 370px;
                width: 300px;
                height: 150px;
            }
            & > div:nth-of-type(12) {
                left: 0;
                top: 390px;
                width: 180px;
                height: 150px;
            }
            & > div:nth-of-type(13) {
                left: 980px;
                top: 540px;
                width: 180px;
                height: 120px;
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition-duration: 0.5s;
                position: relative;
                z-index: 1;
                cursor: pointer;
                &:hover {
                    z-index: 2;
                    box-shadow: 0px 20px 50px 0px #999;
                    transform: translate(0px, -10px) scale(1.05);
                }
            }
        }
    }
    // 福利待遇
    .fringeBox {
        max-width: 1280px;
        // height: 632px;
        margin: 0 auto;
        margin-top: 33px;
        text-align: left;
        // 标题
        .fgbTitle {
            width: 40%;
            height: 100px;
            margin: 0 auto;
            .ftBig {
                width: 100%;
                height: 70px;
                line-height: 70px;
                font-size: 50px;
                text-align: center;
            }
            .ftSmall {
                width: 100%;
                height: 30px;
                line-height: 30px;
                font-size: 22px;
                text-align: center;
            }
        }
        .content {
            width: 100%;
            height: 550px;
            position: relative;
            .center {
                position: absolute;
                top: 100px;
                left: 50%;
                transform: translateX(-50%);
                width: 246px;
                height: 246px;
                background: linear-gradient(to right top, rgb(218, 140, 255), blue);
                border-radius: 50%;

                & > div {
                    width: 200px;
                    height: 200px;
                    text-align: center;
                    line-height: 200px;
                    background-color: white;
                    position: relative;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    border-radius: 50%;
                    // background: url(https://iv.vu818.com/img/vu918.png) no-repeat;
                    // background-position: -398px -328px;
                    // background-size: 650px;
                    font-size: 32px;
                }
            }
            .yuan {
                width: 120px;
                height: 120px;
                border: 15px solid #7781f1;
                border-radius: 50%;
                text-align: center;
                line-height: 100px;
                position: absolute;
                color: #7781f1;
                &::before {
                    content: " ";
                    position: absolute;
                    width: 0;
                    height: 0;
                    border-left: 20px solid transparent;
                    border-right: 20px solid transparent;
                    border-bottom: 40px solid #7781f1;
                    display: block;
                }
            }
            .yuan:nth-of-type(2) {
                top: 19%;
                left: 62%;
                &::before {
                    bottom: -2px;
                    left: -39px;
                    transform: rotate(-116deg) scaleX(1.4);
                }
            }
            .yuan:nth-of-type(3) {
                top: 48%;
                left: 30%;
                &::before {
                    top: -3px;
                    right: -40px;
                    transform: rotate(67deg) scaleX(1.4);
                }
            }

            .yuan:nth-of-type(4) {
                top: 56%;
                left: 59%;
                &::before {
                    top: -19px;
                    left: -30px;
                    transform: rotate(-50deg) scaleX(1.4);
                }
            }
            .yuan:nth-of-type(5) {
                top: 13%;
                left: 29%;
                &::before {
                    top: 53px;
                    right: -40px;
                    transform: rotate(111deg) scaleX(1.4);
                }
            }
            & > ul {
                li {
                    position: absolute;
                    right: 0px;
                    max-width: 50%;
                    & > .name {
                        margin: 20px 0;
                        font-size: 18px;
                        position: relative;
                        &::before {
                            content: " ";
                            // position: absolute;
                            width: 20px;
                            height: 20px;
                            border-radius: 50%;
                            box-sizing: border-box;
                            border: 5px solid #d6d6eb;
                            background-color: #adb1ee;
                            display: inline-block;
                            vertical-align: middle;
                            margin-right: 5px;
                            animation: an 1s infinite;
                            @keyframes an {
                                0% {
                                    transform: scale(1.1);
                                }
                                50% {
                                    transform: scale(0.8);
                                }
                                100% {
                                    transform: scale(1.1);
                                }
                            }
                        }
                    }
                    & > .xian {
                        position: absolute;
                        width: 100px;
                        height: 50px;
                        overflow: hidden;
                        z-index: -1;
                        & > img {
                            position: absolute;
                        }
                    }
                    & > .items {
                        height: 85px;
                        width: 112px;
                        overflow: auto;
                        transition: 0.5s;
                        p {
                            &::before {
                                content: " ";
                                width: 5px;
                                height: 5px;
                                border-radius: 50%;
                                display: inline-block;
                                background-color: darkgray;
                                margin-right: 5px;
                                vertical-align: middle;
                            }
                        }
                        &:hover {
                            &::-webkit-scrollbar {
                                background-color: #eee;
                            }
                            &::-webkit-scrollbar-thumb {
                                background-color: #7781f1;
                            }
                        }
                        &::-webkit-scrollbar {
                            width: 3px;
                            border-radius: 10px;
                            background-color: rgba(238, 238, 238, 0);
                        }
                        &::-webkit-scrollbar-thumb {
                            background-color: #7781f100;
                        }
                    }
                }
                li:nth-of-type(1) {
                    top: -5%;
                    left: 72%;
                    .xian {
                        width: 186px;
                        height: 78px;
                        top: 44px;
                        left: -64px;
                        & > img {
                            top: 0px;
                            left: -219px;
                            width: 400px;
                        }
                    }
                }
                li:nth-of-type(2) {
                    top: 36%;
                    right: 18%;
                    .xian {
                        width: 164px;
                        height: 96px;
                        top: 11px;
                        left: -51px;
                        & > img {
                            top: -117px;
                            right: 26px;
                        }
                    }
                }
                li:nth-of-type(3) {
                    top: 28%;
                    left: 21%;
                    .xian {
                        width: 164px;
                        height: 60px;
                        top: 50px;
                        left: -10px;
                        & > img {
                        }
                    }
                }
                li:nth-of-type(4) {
                    top: 66%;
                    left: 23%;
                    .xian {
                        width: 164px;
                        height: 90px;
                        top: 22px;
                        left: -8px;
                        & > img {
                            bottom: 0px;
                            left: 0px;
                        }
                    }
                }

                li:nth-of-type(5) {
                    top: 66%;
                    left: 47%;
                    .xian {
                        width: 164px;
                        height: 90px;
                        top: 22px;
                        left: -8px;
                        & > img {
                            bottom: 0px;
                            left: 0px;
                        }
                    }
                }
                li:nth-of-type(6) {
                    top: 66%;
                    right: 19%;
                    .xian {
                        width: 164px;
                        height: 96px;
                        top: 11px;
                        left: -51px;
                        & > img {
                            top: -117px;
                            right: 26px;
                        }
                    }
                }
            }
        }
    }
}
</style>

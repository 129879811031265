import { render, staticRenderFns } from "./Recruit.vue?vue&type=template&id=194d6edf&scoped=true&"
import script from "./Recruit.vue?vue&type=script&lang=js&"
export * from "./Recruit.vue?vue&type=script&lang=js&"
import style0 from "./Recruit.vue?vue&type=style&index=0&id=194d6edf&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "194d6edf",
  null
  
)

export default component.exports